<template>
  <section class="fixed-top navigation">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light">

        <b-link href="https://beetelecom.mx" class="navbar-brand">
          <img src="@/assets/img/logo/beetelecom-blanco.svg" alt="logo" />
        </b-link>

        <b-button v-b-toggle.menuToggle class="navbar-toggler border-0">
          <b-icon icon="three-dots-vertical" style="color: #fecd00"></b-icon>
        </b-button>

        <b-collapse class="collapse navbar-collapse" id="menuToggle">
          <ul class="navbar-nav ml-auto" id="collapseResponsive">

            <b-nav-item href="#inicio" class="page-scroll">
              INICIO
            </b-nav-item>

            <b-nav-item href="#nosotros" class="page-scroll">
              NOSOTROS
            </b-nav-item>

            <b-nav-item href="#paquetes" class="page-scroll">
              PAQUETES
            </b-nav-item>

            <b-nav-item href="#contacto" class="page-scroll">
              CONTACTO
            </b-nav-item>

            <b-nav-item href="https://pagos.beetelecom.mx" target="_blank" class="page-scroll">
              PAGOS
            </b-nav-item>

            <b-nav-item href="https://buzon.beetelecom.mx" target="_blank" class="page-scroll">
              BUZÓN
            </b-nav-item>

          </ul>
        </b-collapse>

      </nav>
    </div>
  </section>
</template>

<script>
export default {
  name: "Menú",
};
</script>

<style scoped>
.navbar-light .navbar-nav .nav-link {
  color: #ffffff;
}

.navigation {
  transition: 0.3s ease;
  /* background: rgba(254, 205, 0, .8); */
  background: rgba(0, 0, 0, 0.8);

  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.navigation .navbar .navbar-brand img {
  height: 50px;
}

.navbar .navbar-nav .page-scroll a:hover {
  color: #fecd00 !important;
}

.navbar {
  transition: 0.3s ease;
}

.nav-bg {
  background-color: #fecd00;
  box-shadow: 0px 10px 20px 0px rgba(0, 141, 236, 0.1);
}

.nav-bg .navbar {
  padding: 10px 0;
}

.nav-item {
  padding: 20px 10px;
}

.btn {
  font-size: 18px;
  text-transform: capitalize;
  padding: 15px 60px;
  font-weight: 500;
  border: 0;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  transition: 0.2s ease;
}

.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.btn:active {
  box-shadow: none;
}

.btn-primary {
  background-color: #fecd00;
  color: #ffffff;
  transition: background 1s ease-out;
}

.btn-primary:active {
  background: #fecd00 !important;
}

.btn-primary:hover {
  background: #000000;
  border: solid #fecd00 1px;
}

@media (min-width: 992px) {
  .navbar .collapse {
    display: flex !important;
    justify-content: flex-end !important;
  }
}

@media (max-width: 991px) {
  .navbar .collapse {
    padding-bottom: 20px;
    text-align: center;
  }

  .navbar-light .navbar-toggler {
    background: transparent;
    border: solid #fecd00 1px !important;
    padding: 0.75rem;
  }

  .nav-item {
    padding: 10px 10px;
  }
}
</style>
