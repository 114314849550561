<template>
  <div id="init">
    <Menu />

    <HeroComponent />
    <AboutUsComponent />
    <ReferenceComponent />
    <PricingComponent />
    <ContactComponent />
    <MapComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from "@/components/menu-main.vue";

import HeroComponent from "@/views/sections/hero.vue";
import AboutUsComponent from "@/views/sections/about-us.vue";
import ReferenceComponent from "@/views/sections/reference.vue";
import PricingComponent from "@/views/sections/pricing.vue";
import ContactComponent from "@/views/sections/contact.vue";
import MapComponent from "@/views/sections/map.vue";

export default {
  name: "Hero",

  components: {
    Menu,
    HeroComponent,
    AboutUsComponent,
    ReferenceComponent,
    PricingComponent,
    ContactComponent,
    MapComponent,
  },
};
</script>

<style scoped></style>
