<template>
  <div id="services" class="services section">
    <particles-bg
      type="custom"
      :canvas="{ backgroundColor: '#000000' }"
      :config="config"
      :bg="true"
    />

    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-3 mr-2 my-3 my-md-0">
          <div class="service-item first-service">
            <div class="icon"></div>

            <h4>Simetría</h4>
            <p>
              Tendrás la capacidad de enviar y recibir información al mismo
              tiempo con la misma velocidad.
            </p>
          </div>
        </div>

        <div class="col-lg-3 mx-2 my-3 my-md-0">
          <div class="service-item second-service">
            <div class="icon"></div>

            <h4>Eficiencia</h4>
            <p>
              Con nuestro enlace podrás realizar adecuadamente las funciones en
              tu empresa y obtener resultados.
            </p>
          </div>
        </div>

        <div class="col-lg-3 ml-4 my-3 my-md-0">
          <div class="service-item third-service">
            <div class="icon"></div>

            <h4>Soporte</h4>
            <p>
              Nuestro equipo de Soporte Técnico te brindará la atención oportuna
              24/7.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ParticlesBg } from "particles-bg-vue";
import icon from "@/assets/icon/icon.js";

export default {
  name: "Hero",

  data: function () {
    return {
      config: {
        num: [1, 2],
        rps: 0.1,
        radius: [5, 40],
        life: [1.5, 5],
        v: [2, 3],
        tha: [-30, 30],
        body: icon,
        alpha: [0.6, 0],
        scale: [1.8, 2.0],
        position: "all",
        cross: "dead",
        random: 1,
      },
    };
  },

  components: {
    ParticlesBg,
  },
};
</script>

<style scoped>
.services {
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
}

.service-item {
  position: relative;
  z-index: 1;
  padding: 30px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 10px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  transition: all 0.3s;
}

.service-item .icon {
  margin-left: 0px;
  margin-bottom: 30px;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  transition: all 0.3s;
}

.service-item h4 {
  transition: all 0.3s;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
}

.service-item p {
  transition: all 0.3s;
  font-size: 15px;
  margin-bottom: 20px;
}

.service-item:hover h4,
.service-item:hover p,
.service-item:hover .text-button a {
  color: #fff;
}

.first-service .icon {
  background-image: url(../../assets/img/illustrations/service-icon-01.png);
}

.first-service:hover .icon {
  background-image: url(../../assets/img/illustrations/service-icon-hover-01.png);
}

.second-service .icon {
  background-image: url(../../assets/img/illustrations/service-icon-02.png);
}

.second-service:hover .icon {
  background-image: url(../../assets/img/illustrations/service-icon-hover-02.png);
}

.third-service .icon {
  background-image: url(../../assets/img/illustrations/service-icon-03.png);
}

.third-service:hover .icon {
  background-image: url(../../assets/img/illustrations/service-icon-hover-03.png);
}

.fourth-service .icon {
  background-image: url(../../assets/img/illustrations/service-icon-04.png);
}

.fourth-service:hover .icon {
  background-image: url(../../assets/img/illustrations/service-icon-hover-04.png);
}

.service-item:hover {
  background-image: url(../../assets/img/illustrations/service-bg.png);
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
