<template>
  <div id="paquetes">
    <section class="best-pricing best-pricing2 pricing-padding2">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-6 col-md-8">
            <div class="section-tittle text-center">
              <h2><b>+</b> Velocidad en tu empresa</h2>
            </div>
          </div>

          <div class="col-11 mb-5">
            <div class="section-subtitle text-center">
              <p>
                Independientemente del giro de cada empresa, nuestro Internet
                Dedicado cuenta con la eficiencia para enfrentar los retos de
                conectividad que nuestros clientes necesitan.
              </p>
              <br />

              <span>
                Disfruta de <b>99.98%</b> de disponibilidad en tu servicio
              </span>

              <br /><br /><br />

              <small>¿Estás listo para la velocidad?</small>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="pricing-card-area">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-6 mt-4 mt-md-0">
            <flipper
              class="Card"
              width="100%"
              height="430px"
              :flipped="isFlippedBeeReady"
              @mouseover="isFlippedBeeReady = true"
              @mouseleave="isFlippedBeeReady = false"
            >
              <div class="Card__face" slot="front">
                <div
                  class="single-card text-center mb-30 d-flex justify-content-center align-items-center h-100"
                >
                  <div class="card-top">
                    <span><small>BEE</small>READY</span>
                    <h4>$13,575.00 <span>/ mes</span></h4>
                  </div>
                </div>
              </div>

              <div class="Card__pattern" slot="back">
                <div class="single-card text-center mb-30">
                  <div class="card-top">
                    <span><small>BEE</small>READY</span>
                    <h4>$13,575.00 <span>/ mes</span></h4>
                  </div>

                  <div class="card-bottom">
                    <ul>
                      <li>Enlace de Internet Dedicado</li>
                      <li>de 50/50 Mbps.</li>
                    </ul>

                    <a href="#contact" class="btn card-btn1">¡Lo quiero!</a>
                  </div>
                </div>
              </div>
            </flipper>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-6 mt-4 mt-md-0">
            <flipper
              class="Card"
              width="100%"
              height="430px"
              :flipped="isFlippedBeeFast"
              @mouseover="isFlippedBeeFast = true"
              @mouseleave="isFlippedBeeFast = false"
            >
              <div class="Card__face" slot="front">
                <div
                  class="single-card text-center mb-30 d-flex justify-content-center align-items-center h-100"
                >
                  <div class="card-top">
                    <span><small>BEE</small>FAST</span>
                    <h4>$24,430.00 <span>/ mes</span></h4>
                  </div>
                </div>
              </div>

              <div class="Card__pattern" slot="back">
                <div class="single-card text-center mb-30">
                  <div class="card-top">
                    <span><small>BEE</small>FAST</span>
                    <h4>$24,430.00 <span>/ mes</span></h4>
                  </div>

                  <div class="card-bottom">
                    <ul>
                      <li>Enlace de Internet Dedicado</li>
                      <li>de 100/100 Mbps.</li>
                    </ul>

                    <a href="#contact" class="btn card-btn1">¡Lo quiero!</a>
                  </div>
                </div>
              </div>
            </flipper>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-6 mt-4 mt-md-0">
            <flipper
              class="Card"
              width="100%"
              height="430px"
              :flipped="isFlippedBeeFull"
              @mouseover="isFlippedBeeFull = true"
              @mouseleave="isFlippedBeeFull = false"
            >
              <div class="Card__face" slot="front">
                <div
                  class="single-card text-center mb-30 d-flex justify-content-center align-items-center h-100"
                >
                  <div class="card-top">
                    <span><small>BEE</small>FULL</span>
                    <h4>$39,100.00 <span>/ mes</span></h4>
                  </div>
                </div>
              </div>

              <div class="Card__pattern" slot="back">
                <div class="single-card text-center mb-30">
                  <div class="card-top">
                    <span><small>BEE</small>FULL</span>
                    <h4>$39,100.00 <span>/ mes</span></h4>
                  </div>

                  <div class="card-bottom">
                    <ul>
                      <li>Enlace de Internet Dedicado</li>
                      <li>de 200/200 Mbps.</li>
                    </ul>

                    <a href="#contact" class="btn card-btn1">¡Lo quiero!</a>
                  </div>
                </div>
              </div>
            </flipper>
          </div>
        </div>
      </div>
    </div>

    <section class="terms-conditions mt-5">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="col-11">
            <div class="section-message text-center">
              <p>* Precios a 36 meses</p>
              <br />
              <h3>Elige la mejor velocidad para ti.</h3>
              <small>¡Cotiza ahora!</small>

              <b-row class="d-flex justify-content-center mt-5">
                <ul class="col-12">
                  <li>
                    <b-icon icon="chevron-right" aria-hidden="true"></b-icon>
                    Política BeeNote (si se presenta un fallo por nuestra parte,
                    te extenderemos una nota de crédito que corresponda al
                    tiempo de falla de tu servicio).
                  </li>

                  <li>
                    <b-icon icon="chevron-right" aria-hidden="true"></b-icon>
                    Activación Tradicional: 15 días naturales / sin costo.
                  </li>

                  <li>
                    <b-icon icon="chevron-right" aria-hidden="true"></b-icon>
                    Activación Inmediata: 7 días hábiles $999 (ya incluye IVA).
                  </li>

                  <li>
                    <b-icon icon="chevron-right" aria-hidden="true"></b-icon>
                    Bandwidth simétrico y escalable (servicio dedicado).
                  </li>

                  <li>
                    <b-icon icon="chevron-right" aria-hidden="true"></b-icon>
                    Se entrega Beerouter (router inalámbrico Inteligente) en
                    comodato.
                  </li>

                  <li>
                    <b-icon icon="chevron-right" aria-hidden="true"></b-icon>
                    Disponibilidad y velocidad garantizada.
                  </li>

                  <li>
                    <b-icon icon="chevron-right" aria-hidden="true"></b-icon>
                    Opción a mayor velocidad ¿lo necesitas? Contáctanos.
                  </li>
                </ul>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Flipper from "vue-flipper";

export default {
  name: "Pricing",

  components: { Flipper },

  data: () => ({
    isFlippedBeeReady: false,
    isFlippedBeeFast: false,
    isFlippedBeeFull: false,
  }),
};
</script>

<style src="vue-flipper/dist/vue-flipper.css" />

<style scoped>
.best-pricing {
  position: relative;
}

.best-pricing.pricing-padding {
  padding-top: 100px;
  padding-bottom: 280px;

  background-size: cover;
  background-repeat: no-repeat;
}

.best-pricing.pricing-padding2 {
  padding-top: 100px;
  padding-bottom: 280px;
}

.best-pricing::before {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
  opacity: 0.95;

  background-image: -moz-linear-gradient(
    0deg,
    rgb(249, 33, 141) 0%,
    rgb(108, 25, 246) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgb(249, 33, 141) 0%,
    rgb(108, 25, 246) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgb(249, 33, 141) 0%,
    rgb(108, 25, 246) 100%
  );
}

.best-pricing.best-pricing2::before {
  display: none;
}

.pricing-card-area {
  margin-top: -295px;
}

.pricing-card-area .Card {
  /* border: solid 2px #fff; */
  box-shadow: #ffffff;
}

.pricing-card-area .Card .Card__face {
  width: 100%;
  height: 100%;
  background: #fecd00;
  border-radius: 5px;
}

.pricing-card-area .Card .Card__face .single-card {
  position: relative;
  background: #fecd00;
  padding: 40px 25px;
  border-radius: 6px;
  box-shadow: 0px 0px 9px 0px rgba(131, 93, 248, 0.07);

  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.pricing-card-area .Card .Card__face .single-card::before {
  background-image: url(../../assets/img/illustrations/card_dot.png);
  content: "";
  width: 171px;
  height: 134px;
  position: absolute;
  right: 2px;
  bottom: 2px;
  z-index: -1;

  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.pricing-card-area .Card .Card__face .single-card .card-top {
  border-bottom: 2px solid #000000;
  margin-bottom: 46px;
}

.pricing-card-area .Card .Card__face .single-card .card-top span {
  color: #000000;
  font-size: 26px;
  margin-bottom: 19px;
  display: inline-block;
  font-weight: 1000;
}

.pricing-card-area .Card .Card__face .single-card .card-top span small {
  color: #ffffff;
}

.pricing-card-area .Card .Card__face .single-card .card-top h4 {
  color: #000000;
  font-size: 45px;
  margin-bottom: 26px;
  padding-right: 3px;
  font-weight: 1000;
}

.pricing-card-area .Card .Card__face .single-card .card-top h4 span {
  color: #000000;
  font-size: 16px;
}

.pricing-card-area .Card .Card__face .single-card .active {
  box-shadow: 0px 10px 30px 0px rgba(133, 66, 189, 0.1);
}

.pricing-card-area .Card .Card__face .single-card .active::before {
  right: -20px;
  bottom: -21px;
}

.pricing-card-area .Card .Card__face .single-card .active .btn::before {
  transform: scaleX(1);
  color: #ffffff !important;
  z-index: -1;
}

.pricing-card-area .Card .Card__pattern {
  width: 100%;
  height: 100%;
  /* border: #fecd00 solid 2px; */
  background: #000000;
  border-radius: 5px;
}

.pricing-card-area .Card .Card__pattern .single-card {
  position: relative;
  background: #000000;
  padding: 40px 25px;
  border-radius: 6px;
  box-shadow: 0px 0px 9px 0px rgba(131, 93, 248, 0.07);

  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.pricing-card-area .Card .Card__pattern .single-card {
  position: relative;
  background: #fecd00;
  padding: 40px 25px;
  border-radius: 6px;
  box-shadow: 0px 0px 9px 0px rgba(131, 93, 248, 0.07);

  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.pricing-card-area .Card .Card__pattern .single-card::before {
  background-image: url(../../assets/img/illustrations/card_dot.png);
  content: "";
  width: 171px;
  height: 134px;
  position: absolute;
  right: 2px;
  bottom: 2px;
  z-index: -1;

  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.pricing-card-area .Card .Card__pattern .single-card .card-top {
  border-bottom: 2px solid #000000;
  margin-bottom: 46px;
}

.pricing-card-area .Card .Card__pattern .single-card .card-top span {
  color: #000000;
  font-size: 26px;
  margin-bottom: 19px;
  display: inline-block;
  font-weight: 1000;
}

.pricing-card-area .Card .Card__pattern .single-card .card-top span small {
  color: #ffffff;
}

.pricing-card-area .Card .Card__pattern .single-card .card-top h4 {
  color: #000000;
  font-size: 45px;
  margin-bottom: 26px;
  padding-right: 3px;
  font-weight: 1000;
}

.pricing-card-area .Card .Card__pattern .single-card .card-top h4 span {
  color: #000000;
  font-size: 16px;
}

.pricing-card-area .Card .Card__pattern .single-card .card-bottom ul li {
  color: #000000;
  font-weight: 300;
  margin-bottom: 16px;
  font-size: 16px;

  list-style: none;
}

.pricing-card-area .Card .Card__pattern .single-card .card-bottom .card-btn1 {
  background: #000000;
  color: #fecd00;
}

.pricing-card-area
  .Card
  .Card__pattern
  .single-card
  .card-bottom
  .card-btn1:hover {
  border: 2px solid #000000;
  background: transparent;
  color: #000000;
}

.pricing-card-area
  .Card
  .Card__pattern
  .single-card
  .card-bottom
  ul
  li:last-child {
  margin-bottom: 45px;
}

.pricing-card-area .Card .Card__pattern .single-card .active {
  box-shadow: 0px 10px 30px 0px rgba(133, 66, 189, 0.1);
}

.pricing-card-area .Card .Card__pattern .single-card .active::before {
  right: -20px;
  bottom: -21px;
}

.pricing-card-area .Card .Card__pattern .single-card .active .btn::before {
  transform: scaleX(1);
  color: #ffffff !important;
  z-index: -1;
}

.section-tittle h2 {
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 90px;

  color: #61e8cf;
}

.section-tittle h2 b {
  color: #fecd00;
  font-weight: 900;
  font-size: 55px;
}

.section-subtitle p {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;

  color: #ffffff;
}

.section-subtitle span {
  font-size: 17.5px;
  font-weight: 400;
  margin-bottom: 10px;

  color: #fecd00;
}

.section-subtitle small {
  font-size: 25px;
  font-weight: 800;
  margin-bottom: 10px;

  color: #ffffff;
}

.section-message p {
  font-size: 15px;
  font-weight: 500;

  color: #ffffff;
}

.section-message h3 {
  font-size: 20px;
  font-weight: 200;

  color: #fecd00;
}

.section-message small {
  font-size: 30px;
  font-weight: 600;

  color: #fecd00;
}

.section-message ul li {
  font-size: 15px;
  font-weight: 100;
  list-style: none;

  color: #ffffff;
}
</style>
